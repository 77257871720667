<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 검토요청일 -->
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="1D"
            label="검토요청일"
            name="reviewRequestPeriod"
            v-model="searchParam.reviewRequestPeriod"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 진행상태 -->
          <c-select
            type="search"
            stepperGrpCd="MDM_CHEM_REVIEW_STEP_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="mdmChemReviewStepCd"
            label="진행상태"
            v-model="searchParam.mdmChemReviewStepCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="사전검토 목록"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="등록" icon="add" @btnClicked="openPop" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'chem-review',
  data() {
    return {
      searchParam: {
        plantCd: null,
        reviewRequestPeriod: [],
        mdmChemReviewStepCd: null,
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'materialCd',
            field: 'materialCd',
            label: '자재일련번호',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'name',
            field: 'name',
            label: '화학자재명',
            child: [
              {
                name: 'materialName',
                field: 'materialName',
                label: '한글',
                align: 'left',
                type: 'link',
                style: 'width:250px',
                sortable: true,
              },
              {
                name: 'materialNameEn',
                field: 'materialNameEn',
                label: '영문',
                align: 'left',
                style: 'width:200px',
                sortable: true,
              },
            ]
          },
          {
            name: 'mdmChemReviewStepName',
            field: 'mdmChemReviewStepName',
            label: '진행상태',
            align: 'center',
            style: 'width:90px',
            sortable: true,
          },
          {
            name: 'regUserName',
            field: 'regUserName',
            label: '작성자',
            align: 'center',
            style: 'width:90px',
            sortable: true,
          },
          {
            name: 'review',
            field: 'review',
            label: '검토',
            align: 'center',
            style: 'width:90px',
            sortable: true,
            type: 'proxy',
            component: () => import(`${'@/pages/mdm/mam/review/reviewStatusProxy.vue'}`)
          },
          {
            name: 'plantNames',
            field: 'plantNames',
            label: '사업장명',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.mdm.mam.review.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = '사전검토 상세';
      this.popupOptions.param = {
        mdmChemReviewId: row ? row.mdmChemReviewId : '',
      };
      this.popupOptions.target = () => import(`${'./chemReviewDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
